<template>
  <form class="organization-search" v-if="$store.getters.isLoaded(['organizations'])" @submit.prevent="">
    <div class="form-group">
      <label for="searchInput">Search for Organization</label>
      <input
        type="search"
        class="form-control"
        id="searchInput"
        v-model="query"
        @input="search"
        @keydown.up="selectResult('prev')"
        @keydown.down="selectResult('next')"
        @keydown.enter="itemSelected(selectedOrganizationId)"
        :preventOnKeyDown="['ArrowUp', 'ArrowDown', 'Enter']"
        autofocus
      />
    </div>

    <ul v-if="searchResults && searchResults.length >= 1" class="organization-search__results">
      <li
        v-for="(organization, i) in searchResults"
        :key="i"
        class="organization-search__result"
        :class="{ selected: selectedOrganizationId === organization.id }"
        @mouseenter="selectResult('byId', organization.id)"
        @click="itemSelected(organization.id)"
      >
        {{ organization.name }}
      </li>
    </ul>
  </form>
</template>

<script>
import Fuse from 'fuse.js-ie11'

const searchOptions = {
  keys: ['name'],
  includeScore: true
}

export default {
  data() {
    return {
      query: '',
      searchResults: [],
      selectedOrganizationId: null
    }
  },
  methods: {
    search() {
      const organizations = this.$store.state.organizations
      const fuse = new Fuse(organizations, searchOptions)
      let results = fuse.search(this.query, { limit: 5 })
      this.searchResults = results
        .filter(result => result.score < 0.8)
        .map(result => {
          return result.item
        })
      this.selectedOrganizationId = this.searchResults.length >= 1 ? this.searchResults[0].id : null
    },
    selectResult: function(action, resultId) {
      const { searchResults, selectedOrganizationId } = this
      const index = searchResults.findIndex(result => result.id === selectedOrganizationId)
      if (action === 'byId') {
        this.selectedOrganizationId = resultId
      } else if (action === 'next' && searchResults.length - 1 > index) {
        this.selectedOrganizationId = searchResults[index + 1].id
      } else if (action === 'prev' && index > 0) {
        this.selectedOrganizationId = searchResults[index - 1].id
      }
    },
    itemSelected(organizationId) {
      const organization = this.$store.state.organizations.find(o => o.id === organizationId)
      this.$emit('organization-selected', organization)
      this.searchResults = []
      this.selectedOrganizationId = null
      this.query = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.organization-search {
  position: relative;

  &__results {
    @extend %cleanlist;

    position: absolute;
    z-index: 100;
    width: 100%;

    background: #fff;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.125);
    border-radius: 3px;
  }

  &__result {
    display: flex;
    align-items: center;
    cursor: pointer;

    padding: 0.5rem 1.5rem;

    &.selected {
      background: #d5dfeb;
    }
  }
}
</style>
