<template>
  <form @submit.prevent="submit">
    <div
      class="list-group-item d-flex justify-content-between align-items-center"
      style="margin-bottom: 37px;"
      v-if="shipment.organizationId && shipment.organizationName"
    >
      <span>
        <span class="d-inline-block mr-3">🏠</span>
        <span>{{ shipment.organizationName }}</span>
      </span>
      <button class="btn btn-sm btn-outline-danger" type="button" @click="resetOrganization">
        Remove
      </button>
    </div>
    <OrganizationSearch @organization-selected="selectOrganization" v-else />
    <div class="d-flex align-items-center mt-4 mb-3 pl-2">
      <h1 class="h5 d-inline-block mb-0">Address</h1>
      <button class="btn btn-sm btn-light ml-3" type="button" @click="resetReceiver">Reset</button>
    </div>
    <div class="form-group mb-2">
      <input
        type="text"
        class="form-control form-control-sm mb-3"
        id="name"
        ref="autocompleteInput"
        placeholder="Address Search"
        v-model="autocompleteInput"
      />
    </div>
    <div class="form-group mb-2">
      <label for="contactPerson" class="ml-2 pt-0 col-form-label">Contact Person</label>
      <input type="text" class="form-control form-control-sm" id="name" v-model.trim="shipment.contactPerson" />
    </div>
    <div class="form-group mb-2">
      <label for="name" class="ml-2 pt-0 col-form-label">Name</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="name"
        v-model.trim="shipment.receiver.name"
        required
      />
    </div>
    <div class="form-group mb-2">
      <label for="name2" class="ml-2 pt-0 col-form-label">Name 2</label>
      <input type="text" class="form-control form-control-sm" id="name2" v-model.trim="shipment.receiver.name2" />
    </div>
    <div class="row">
      <div class="col-9">
        <div class="form-group mb-2">
          <label for="street" class="ml-2 pt-0 col-form-label">Street</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="street"
            v-model.trim="shipment.receiver.street"
            required
          />
        </div>
      </div>
      <div class="col col-3">
        <div class="form-group mb-2">
          <label for="streetNumber" class="ml-2 pt-0 col-form-label">No.</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="streetNumber"
            v-model.trim="shipment.receiver.streetNumber"
            required
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="form-group mb-2">
          <label for="zip" class="ml-2 pt-0 col-form-label">ZIP</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="zip"
            minlength="5"
            maxlength="5"
            v-model.trim="shipment.receiver.zip"
            required
          />
        </div>
      </div>
      <div class="col-9">
        <div class="form-group mb-2">
          <label for="city" class="ml-2 pt-0 col-form-label">City</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="city"
            v-model.trim="shipment.receiver.city"
            required
          />
        </div>
      </div>
    </div>

    <div class="mt-3">
      <h1 class="h5">Type</h1>
      <select class="custom-select" v-model="shipment.type" required>
        <option value="customerContact">Customer Contact</option>
        <option value="eMailFunnel">E-Mail Funnel</option>
        <option value="tradeFairContact">Trade Fair Contact</option>
      </select>
    </div>

    <div class="mt-4">
      <h1 class="h5">Shipment Date</h1>
      <div class="custom-control-inline custom-switch mt-2 mb-2" style="width: 200px">
        <input
          type="checkbox"
          class="custom-control-input success"
          id="setShipmentDate"
          name="setShipmentDate"
          v-model="shipment.setShipmentDate"
        />
        <label class="custom-control-label" for="setShipmentDate">Set Shipment Date</label>
      </div>

      <div class="d-inline-block" v-if="shipment.setShipmentDate">
        <label>Not before:</label>
        <input
          type="date"
          class="form-control form-control-sm d-inline-block ml-2 d-inline-block"
          style="width: 160px;"
          v-model="shipment.shipmentDate"
          required
        />
      </div>
    </div>
    <div class="mt-4">
      <h1 class="h5">Items</h1>
      <div v-for="type in Object.keys(shipment.items)" :key="type">
        <div class="custom-control-inline custom-switch mt-2 mb-2">
          <input
            type="checkbox"
            class="custom-control-input success"
            :id="type"
            :name="type"
            v-model="shipment.items[type].selected"
          />
          <label class="custom-control-label block" :for="type">{{ type | startCase }}</label>
        </div>
        <div
          class="d-inline-block"
          v-if="shipment.items[type].selected && typeof shipment.items[type].amount !== 'undefined'"
        >
          <input
            type="text"
            class="form-control form-control-sm d-inline-block mr-2 text-right"
            style="width: 50px;"
            id="name"
            v-model.number="shipment.items[type].amount"
            required
          />
          Pieces
        </div>
      </div>
    </div>
    <div class="mt-2 d-flex justify-content-end">
      <button class="btn btn-success" type="submit" :disabled="busy">Add</button>
    </div>
  </form>
</template>

<script>
import OrganizationSearch from '@/components/OrganizationSearch'
import { auth, db } from '@/shared/firebase'
import { Loader } from '@googlemaps/js-api-loader'

export default {
  components: { OrganizationSearch },
  data() {
    return {
      busy: false,
      autocompleteInput: '',
      shipment: {
        contactPerson: '',
        receiver: {
          name: '',
          name2: '',
          street: '',
          streetNumber: '',
          zip: '',
          city: '',
          countryCode: 'DE'
        },
        organizationId: null,
        organizationName: null,
        type: null,
        items: {
          sampleShipmentKiosk: {
            selected: false
          },
          sampleShipmentLieferdienst: {
            selected: false
          },
          businessBrochures: {
            selected: false,
            amount: ''
          },
          catalogs: {
            selected: false,
            amount: ''
          },
          easterCatalogs: {
            selected: false,
            amount: ''
          },
          christmasCatalogs: {
            selected: false,
            amount: ''
          },
          notepads: {
            selected: false,
            amount: ''
          },
          tablets: {
            selected: false,
            amount: ''
          },
          scanners: {
            selected: false,
            amount: ''
          }
        },
        setShipmentDate: false,
        shipmentDate: new Date().toISOString().substring(0, 10)
      }
    }
  },
  mounted() {
    const loader = new Loader({
      apiKey: 'AIzaSyAnSj0fTmxlpWMgeSXlZfkb_7RGo_3Bs1c',
      libraries: ['places']
    })

    loader
      .load()
      .then(google => {
        const autocomplete = new google.maps.places.Autocomplete(this.$refs.autocompleteInput, {
          componentRestrictions: { country: 'de' }
        })
        autocomplete.addListener('place_changed', () => {
          this.autocompleteInput = ''
          const place = autocomplete.getPlace()
          if (place.types.includes('point_of_interest')) this.shipment.receiver.name = place.name
          place.address_components.forEach(comp => {
            if (comp.types.includes('route')) this.shipment.receiver.street = comp.long_name
            if (comp.types.includes('street_number')) this.shipment.receiver.streetNumber = comp.long_name
            if (comp.types.includes('locality')) this.shipment.receiver.city = comp.long_name
            if (comp.types.includes('postal_code')) this.shipment.receiver.zip = comp.long_name
          })
        })
      })
      .catch(e => {
        console.warn(e.message)
      })
  },
  computed: {},
  methods: {
    selectOrganization(organization) {
      this.shipment.organizationId = organization.id
      this.shipment.organizationName = organization.name

      this.resetReceiver()

      if (organization.shippingAddress) {
        this.shipment.receiver = {
          ...this.shipment.receiver,
          ...organization.shippingAddress
        }
      }
    },
    resetReceiver() {
      this.shipment.contactPerson = ''
      this.shipment.receiver = {
        name: '',
        name2: '',
        street: '',
        streetNumber: '',
        zip: '',
        city: '',
        countryCode: 'DE'
      }
    },
    resetOrganization() {
      this.shipment.organizationId = null
      this.shipment.organizationName = null
    },
    toggleItems(e) {
      const { name } = e.target
      const item = this.shipment.items[name]
      if (typeof item === 'undefined') {
        this.$set(this.shipment.items, [name], {
          amount: ''
        })
      } else {
        this.$delete(this.shipment.items, [name])
      }
    },
    submit() {
      this.busy = true
      const {
        receiver,
        contactPerson,
        items,
        organizationId,
        organizationName,
        setShipmentDate,
        shipmentDate,
        type
      } = this.shipment
      const data = {
        receiver,
        contactPerson,
        organizationId,
        organizationName,
        items: {},
        createdAt: new Date(),
        shipped: false,
        shippedAt: null,
        type,
        user: {
          uid: auth.currentUser.uid,
          displayName: auth.currentUser.displayName
        },
        whPrio: 1
      }
      if (setShipmentDate) data.shipmentDate = shipmentDate
      Object.keys(items).forEach(key => {
        if (items[key].selected) data.items[key] = items[key]
      })
      if (Object.keys(data.items).length === 0) {
        this.busy = false
        return alert('Please selet at least one item')
      }
      db.collection('materialShipments')
        .add(data)
        .then(() => {
          this.busy = false
          this.$emit('close')
        })
        .catch(err => {
          this.busy = false
          alert(err.message)
        })
    }
  },
  filters: {
    startCase(str) {
      return _.startCase(str)
    }
  }
}
</script>
