<template>
  <Layer title="Shipment Tracking" width="50rem" @close="$emit('close')">
    <div v-if="!loaded">Loading...</div>
    <div v-else>
      <div v-for="shipment in shipments" :key="shipment.id">
        <h1 class="h5 m-3">
          <a :href="`https://www.dhl.de/de/privatkunden.html?piececode=${shipment.shipmentNumber}`" target="_blank"
            >DHL {{ shipment.shipmentNumber }}</a
          >
        </h1>
        <ul class="list-group" v-if="shipment.tracking">
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
            v-for="event in shipment.tracking.events"
            :key="event.timestamp"
          >
            <div>
              <small class="d-block">{{ event.timestamp | timestamp }}</small>
              {{ event.status }}
            </div>
            <span
              class="badge badge-pill"
              :class="event.statusCode === 'delivered' ? 'badge-success' : 'badge-secondary'"
            >
              {{ event.statusCode }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </Layer>
</template>

<script>
import Layer from '@/shared/Layer'
import { db } from '@/shared/firebase'

export default {
  components: { Layer },
  props: {
    shipmentIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      shipments: null
    }
  },
  async created() {
    const shipments = await Promise.all(
      this.shipmentIds.map(shipmentId => {
        return db
          .doc(`shipments/${shipmentId}`)
          .get()
          .then(doc => doc.data())
      })
    )
    this.shipments = shipments
    this.loaded = true
  },
  filters: {
    timestamp(date) {
      return `${date.substr(8, 2)}.${date.substr(5, 2)}. - ${date.substr(11, 5)}`
    }
  }
}
</script>
