<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h1 class="h2 mb-0">Material Shipments</h1>
      <button class="btn btn-sm btn-success" @click="showAddLayer = true">Add Shipment</button>
    </div>
    <ListSkeleton v-if="materialShipments === null" />
    <ul v-else-if="materialShipments.length > 0" class="list-group">
      <li class="list-group-item" v-for="shipment in materialShipments" :key="shipment.id">
        <div class="d-flex justify-content-between align-items-center">
          <div style="font-size: 0.85rem">
            <select @change="changePrio(shipment.id, $event.target.value)" class="mr-2">
              <option :value="1" :selected="shipment.whPrio === 1">P1</option>
              <option :value="2" :selected="shipment.whPrio === 2 || typeof shipment.whPrio === 'undefined'">P2</option>
              <option :value="3" :selected="shipment.whPrio === 3">P3</option>
              <option :value="0" :selected="shipment.whPrio === 0">P0</option>
            </select>
            <span v-if="shipment.organizationName">
              <strong style="font-size: 1rem;">🏠 {{ shipment.organizationName }}</strong>
              <hr />
              {{ shipment.receiver.name }}
            </span>
            <span v-else>
              <strong style="font-size: 1rem;">{{ shipment.receiver.name }}</strong>
            </span>
            <br />
            {{ shipment.contactPerson }}<br />
            {{ shipment.receiver.street }} {{ shipment.receiver.streetNumber }}<br />
            {{ shipment.receiver.zip }} {{ shipment.receiver.city }}<br />
          </div>

          <div class="d-flex align-items-center">
            <ul class="list-unstyled mr-4 text-right">
              <li v-for="(item, itemType) in shipment.items" :key="itemType">
                <span v-if="item.amount">{{ item.amount }} x </span>
                <span
                  >{{ itemType | startCase }}
                  <span v-if="itemType === 'sampleShipment' && shipment.type"
                    >({{ shipment.type | startCase }})</span
                  ></span
                >
              </li>
            </ul>
            <div class="d-flex flex-column">
              <span class="badge badge-pill badge-secondary">
                {{ shipment.createdAt.toDate() | dateAndTime }}
              </span>
              <span class="badge badge-pill badge-warning mt-2" v-if="shipment.shippedAt === null">
                Not yet shipped
              </span>
              <button class="btn badge badge-pill badge-success mt-2" @click="loadShipments(shipment.id)" v-else>
                {{ shipment.shippedAt.toDate() | dateAndTime }}
              </button>
              <span v-if="shipment.user" class="badge badge-pill badge-info mt-2">{{ shipment.user.displayName }}</span>
              <span v-if="shipment.shipmentDate" class="badge badge-pill badge-danger mt-2">
                Not before {{ shipment.shipmentDate | dateStringToDate }}
              </span>
            </div>
            <DotMenu>
              <li @click="loadShipments(shipment.id)">
                Tracking
              </li>
              <li
                v-if="
                  !shipment.shipped && ($store.state.role === 'admin' || (shipment.user && shipment.user.uid === uid))
                "
                class="text-danger"
                @click="deleteShipment(shipment.id)"
              >
                Delete
              </li>
            </DotMenu>
          </div>
        </div>
      </li>
    </ul>
    <div class="alert alert-secondary" role="alert" v-else>
      No Material Shipments has been found
    </div>
    <Layer
      v-if="showAddLayer"
      title="Add Material Shipment"
      width="40rem"
      padding="1.5rem 1.5rem 2rem"
      @close="showAddLayer = false"
    >
      <MaterialShipmentForm @close="showAddLayer = false" />
    </Layer>
    <ShipmentTrackingLayer
      v-if="shipmentTrackingLayerIds"
      :shipmentIds="shipmentTrackingLayerIds"
      @close="shipmentTrackingLayerIds = null"
    />
  </div>
</template>

<script>
import { db, auth } from '@/shared/firebase'
import Layer from '@/shared/Layer'
import ListSkeleton from '@/components/ListSkeleton'
import MaterialShipmentForm from '@/components/MaterialShipmentForm'
import ShipmentTrackingLayer from '@/components/ShipmentTrackingLayer'
import DotMenu from '@/shared/DotMenu'

export default {
  components: { Layer, ListSkeleton, MaterialShipmentForm, ShipmentTrackingLayer, DotMenu },
  data() {
    return {
      materialShipments: null,
      materialShipmentsSubscription: null,
      showAddLayer: false,
      deletionConfirmId: null,
      shipmentTrackingLayerIds: null
    }
  },
  created() {
    this.materialShipmentsSubscription = db
      .collection('materialShipments')
      .orderBy('createdAt', 'desc')
      .limit(200)
      .onSnapshot(snapshot => {
        this.materialShipments = snapshot.docs.map(doc => {
          return { ...doc.data(), id: doc.id }
        })
      })
  },
  beforeDestroy() {
    this.materialShipmentsSubscription()
  },
  methods: {
    changePrio(materialShipmentId, whPrio) {
      db.doc(`materialShipments/${materialShipmentId}`).update({ whPrio: parseFloat(whPrio) })
    },
    deleteShipment(shipmentId) {
      const shipment = this.materialShipments.find(s => s.id === shipmentId)
      const confirmed = confirm(`Delte Material Shipment for ${shipment.receiver.name}?`)

      if (confirmed) {
        db.doc(`materialShipments/${shipmentId}`).delete()
      }
    },
    async loadShipments(shipmentId) {
      const shipmentIds = await db
        .collection('shipments')
        .where('materialShipmentId', '==', shipmentId)
        .get()
        .then(snapshot => {
          return snapshot.docs.map(doc => doc.id)
        })
      if (shipmentIds.length === 0) return alert('No shipments found')
      this.shipmentTrackingLayerIds = shipmentIds
    }
  },
  computed: {
    uid() {
      return auth.currentUser.uid
    }
  },
  filters: {
    dateAndTime(date) {
      const dateString = date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit' })
      const timeString = date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })
      return `${dateString} - ${timeString}`
    },
    dateStringToDate(dateString) {
      return `${dateString.substr(8, 2)}.${dateString.substr(5, 2)}.${dateString.substr(0, 4)}`
    },
    startCase(str) {
      return _.startCase(str)
    }
  }
}
</script>
